<template>
  <form-consulta-cadastro
    :formCadastro="formCadastro"
    :controller="controller"
  />
</template>

<script>
import { ConsultaProjeto } from '@/proxy-api/src/services/classes/Projeto/ConsultaProjeto';

import FormConsultaCadastro from '@/components/form/FormConsultaCadastro';
import FormProjeto from '@/views/projeto/form/FormProjeto';

export default {
  components: { FormConsultaCadastro },

  data: function () {
    return {
      controller: new ConsultaProjeto(),
      formCadastro: FormProjeto,
    };
  },
};
</script>
